@import "../../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities/text";
@import "../../../node_modules/bootstrap/scss/utilities/visibility";
@import 'themes';

@import '~@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
};

@import "../../../node_modules/angular-calendar/css/angular-calendar.css";

/* You can add global styles to this file, and also import other style files */

.form-control {
  margin-bottom: 1em;
}

.action-icon {
  cursor: pointer;
}

.text-center{
  text-align: center;
}

pagination-template > ul > li {
  &.current {
    border-radius: 5px;
    background: var(--color-primary-500) !important;
    font-weight: 600;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    outline-color: var(--color-primary-500);
  }
}

.nb-dialog-middle {
  nb-dialog-container {
    min-width: 70vw !important;
    min-height: 70vh !important;
  }
}

// Bootstrap Utilities
.pl-0 {
  padding-left: 0px !important;
}

.w-100 {
  width: 100% !important;
}

.float-right {
  float: right !important;
}

.modal-middle {
  width: 100% !important;
  height: 100% !important;

  .cdk-visually-hidden {
    width: 99% !important;
    height: 99% !important;
  }

  nb-dialog-container {
    width: 100% !important;
    height: 100% !important;
  }
}

.scoring-component {
 max-width: calc(100% - 32px) !important;
  .select-button {
    min-width: auto !important;
  }
}

.cursor-hover {
  &:hover {
     cursor: pointer;
  }
}
