@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/default';

$nb-enable-css-custom-properties: true;

$nb-themes: nb-register-theme((
  color-primary-100: #FEF2CD,
  color-primary-200: #FDE29C,
  color-primary-300: #FACD6A,
  color-primary-400: #F6B745,
  color-primary-500: #F1950A,
  color-primary-600: #CF7707,
  color-primary-700: #AD5C05,
  color-primary-800: #8B4403,
  color-primary-900: #733301,
  color-primary-transparent-100: rgba(241, 149, 10, 0.08),
  color-primary-transparent-200: rgba(241, 149, 10, 0.16),
  color-primary-transparent-300: rgba(241, 149, 10, 0.24),
  color-primary-transparent-400: rgba(241, 149, 10, 0.32),
  color-primary-transparent-500: rgba(241, 149, 10, 0.4),
  color-primary-transparent-600: rgba(241, 149, 10, 0.48),
), hearing, default);


nb-sidebar,
nb-sidebar > div.main-container {
  transition: width 0.5s ease;
}
